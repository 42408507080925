import React from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';
import ipad from '../assets/images/ipad.png';
import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import bgMaster from '../assets/images/bg-masthead.jpg';
import women from '../assets/images/women.jpg';
import grandma from '../assets/images/grandma.jpg';
import image from '../assets/images/grandma.jpg';


const PurposePage = () => (
  <Layout>
    <Header />
    <section id="purpose" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <h2> OUR PURPOSE</h2>
        </div>
        <br />
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-md-12">
            <h4> Two Beta authors and sponsors projects in rural developing economies that: </h4>
            <br/>
          </div>
          <div className="col-md-6">
            <p><h1>1.</h1> Organically Boost Local Industry Growth through advisory & strategic financing. </p>
          </div>
          <div className="col-md-6">
            <p><h1>2.</h1> Solidify foundations for economic expansion by satisfying the Four Pillars of Human Necessity: Adequate Nutrition, Comfortable Shelter, Eye-Opening Education and Basic Healthcare</p>
          </div>
        </div>
        <br />
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <img className="img-fluid mb-3 mb-lg-0" src={grandma} alt="" />
        </div>
      </div>
    </section>
    <SocialLinks />
    <Footer />
  </Layout>
);

export default PurposePage;
